import persist from '@alpinejs/persist'
import Alpine from 'alpinejs'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import fitvids from 'fitvids'

Alpine.plugin(persist)

window.Alpine = Alpine

Alpine.start()

if (document.readyState !== 'loading') {
		eventHandler();
	} else {
		document.addEventListener('DOMContentLoaded', eventHandler );
}

function eventHandler(){

	fitvids();

	// is video?

	if (document.getElementById('vid_wrap') != null) {
	var mv = null;
	mv = document.getElementById('vid_wrap');
	fitvids(mv);

	}

}